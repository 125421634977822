import React from 'react'
import CakeAndBakery from '../templates/CakeAndBakery.js'
import SushiBar from '../templates/SushiBar'
import Tastyc from '../templates/Tastyc'
import MenuList from '../components/MenuList.js'
import Campaign from '../components/Campaign.js'
import Asia from '../templates/Asia.js'
import { useParams } from 'react-router-dom'
import { use2Collection } from '../hooks/use2Collection.js'
// import { useCollection } from '../hooks/useCollection.js'
import Splash from '../components/Splash.js'
import SocialButtons from '../components/SocialButtons.js'
import Default from '../templates/Default.js'

function buildCSS(document, key, defaultVal) {
    var css = ''
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        css += ' ' + document.styles[key]
    } else if (defaultVal) {
        css += ' ' + defaultVal
    }

    return css
}

function displayLoading(msg) {
    return <div className='loading flex flex-col justify-center items-center fixed top-0 left-0 w-full h-full'>{msg}</div>
}

export default function Mamenu() {
    const { name } = useParams()
    const { documents: restaurants, results: menus, error } = use2Collection('restaurants', 'menus', ['path', '==', name], null, 1)

    if (error) {
        return <div className='error'>{error}</div>
    }

    if (!restaurants && !menus) {
        return displayLoading('Die Seite wird geladen...')
    }

    if (restaurants && !menus) {
        return displayLoading('Menüs werden geladen...')
    }

    const restaurant = restaurants[0]

    // Title
    var titleCSS = buildCSS(restaurant, 'titleFontFamily')
    titleCSS += buildCSS(restaurant, 'titleFontSize', 'text-2xl')
    titleCSS += buildCSS(restaurant, 'titleFontStyle')

    // Description 
    var descriptionCSS = buildCSS(restaurant, 'descriptionFontFamily')
    descriptionCSS += buildCSS(restaurant, 'descriptionFontSize', 'text-base')
    descriptionCSS += buildCSS(restaurant, 'descriptionFontStyle')

    return (
        <>
            {restaurant.template === 'cakeandbakery' && <CakeAndBakery />}
            {
                restaurant.template === 'sushibar' &&
                <SushiBar>
                    <MenuList />
                    {(restaurant.campaign !== '' && restaurant.campaign !== 'none') && <Campaign display='main-horizontal' type='restaurant' collection={restaurant} />}
                </SushiBar>
            }
            {
                restaurant.template === 'default' &&
                <>
                    <Splash sessionStorageKey='splashBeforeMenuList' restaurant={restaurant} />
                    {<Default restaurant={restaurant}>
                        <header>
                            {/* <h2 className={'text-gray-600 text-center mt-2 font-semibold ' + titleCSS}>{restaurant.title}</h2> */}
                            <h3 className={'text-center font-semibold mt-2 ' + descriptionCSS}>{restaurant.description}</h3>
                        </header>
                        <MenuList restaurant={restaurant} menus={menus} />
                        {(restaurant.campaign !== '' && restaurant.campaign !== 'none') && <Campaign display='main-horizontal' type='restaurant' collection={restaurant} />}
                        <SocialButtons restaurant={restaurant} />
                    </Default>}
                </>
            }
            {
                restaurant.template === 'asia' &&
                <>
                    <Splash sessionStorageKey='splashBeforeMenuList' restaurant={restaurant} />
                    {<Asia restaurant={restaurant}>
                        <header>
                            {/* <h2 className={'text-gray-600 text-center mt-2 font-semibold ' + titleCSS}>{restaurant.title}</h2> */}
                            <h3 className={'text-center font-semibold mt-2 ' + descriptionCSS}>{restaurant.description}</h3>
                        </header>
                        <MenuList restaurant={restaurant} menus={menus} />
                        {(restaurant.campaign !== '' && restaurant.campaign !== 'none') && <Campaign display='main-horizontal' type='restaurant' collection={restaurant} />}
                        <SocialButtons restaurant={restaurant} />
                    </Asia>}
                </>
            }
            {restaurant.template === 'tastyc' && <Tastyc />}
        </>
    )
}
