import React, { useEffect, useState } from 'react'

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default function Splash({ sessionStorageKey, restaurant }) {
    const wasSeen = sessionStorage.getItem(sessionStorageKey)

    useEffect(async () => {
        let ranAnimation = false
        const image = Array.from(document.getElementsByClassName('splash-img'))[0]

        // Display ad only if image loads in under 500ms
        for (let i = 0; i < 50; i++) {
            if (image && image.complete) {
                // Display the ad
                const el = Array.from(document.getElementsByClassName('splash-holder'))[0]
                el.classList.remove('hidden')

                // Trigger animation
                const screen = document.getElementById('splashScreen')
                // Important to change this based on configuration when bg-color is set by user
                screen.classList.add('text-white', 'bg-black', 'animate-splash-scene')
                screen.classList.remove('bg-gray-100')

                ranAnimation = true
                break
            }
            await sleep(10)
        }

        // Explicitly call in case animation is never triggered
        // which in turn will remove the splash screen
        if (!ranAnimation) {
            handleAnimationEnd()
        }

        // Set session storage key
        if (!sessionStorage.getItem(sessionStorageKey)) {
            sessionStorage.setItem(sessionStorageKey, "true")
        }
    })

    const handleAnimationEnd = () => {
        const el = document.getElementById('splashScreen')
        if (el) {
            el.style.display = "none"
        }
    }

    const getMarketingImage = (restaurant) => {
        let campaign = restaurant.campaign ? restaurant.campaign : 'none'
        switch (campaign) {
            case 'redbull':
                return <img src={`${process.env.PUBLIC_URL}/img/redbull-marketing-5.jpeg`} alt="Red Bull Marketing"
                    className="splash-img object-contain object-center" />
            case 'coke-1':
                return <img src={`${process.env.PUBLIC_URL}/img/coke-marketing-3.jpg`} alt="Coke Marketing"
                    className="splash-img object-contain object-center" />
            case 'coke-2':
                return <img src={`${process.env.PUBLIC_URL}/img/coke-marketing-3.jpg`} alt="Coke Marketing"
                    className="splash-img object-contain object-center" />
            default: return
        }
    }

    const getMarketingTagline = (restaurant) => {
        let campaign = restaurant.campaign ? restaurant.campaign : 'none'
        switch (campaign) {
            case 'redbull':
                return <p className="text-white text-center font-bold text-lg">Redbull Wiiings for Every Taste</p>
            case 'coke-1':
                return <p className="text-white text-center font-bold text-lg">Real Magic</p>
            case 'coke-2':
                return <p className="text-white text-center font-bold text-lg">Real Magic</p>
            default: return
        }
    }

    return (
        <>
            {!wasSeen && (
                <div id="splashScreen" onAnimationEnd={handleAnimationEnd} className="flex flex-col justify-center items-center fixed top-0 left-0 w-full h-full z-40 bg-gray-100">
                    <div className="splash-holder hidden bg-black justify-center items-center">
                        {getMarketingImage(restaurant)}
                        {getMarketingTagline(restaurant)}
                    </div>
                </div>
            )}
        </>
    )
}