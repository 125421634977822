import React from 'react'

export default function Campaign({ display, type, collection }) {
    const getMarketingImage = (display, type, collection) => {
        let campaign = collection.campaign ? collection.campaign : 'none'
        if (display == 'main-horizontal') { // Position-Orientation
            if (type == 'restaurant') {     // Page Type
                switch (campaign) {         // Campaign
                    case 'redbull':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/redbull-marketing-1-min.jpg`}
                            alt="Redbull Marketing" />
                    case 'coke-1':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-2.jpg`}
                            alt="Coke Marketing" />
                    case 'coke-2':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-2.jpg`}
                            alt="Coke Marketing" />
                    default: return
                }
            }
            if (type == 'menu') {           // Page Type
                switch (campaign) {         // Campaign
                    case 'redbull':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/redbull-marketing-1-min.jpg`}
                            alt="Redbull Marketing" />
                    case 'coke-1':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-2.jpg`}
                            alt="Coke Marketing" />
                    case 'coke-2':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-5.gif`}
                            alt="Coke Marketing" />
                    default: return
                }
            }
        }
        else if (display == 'sidebar-vertical') { // Position-Orientation
            if (type == 'restaurant') {           // Page Type
                switch (campaign) {               // Campaign
                    case 'redbull':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/redbull-marketing-3-min.jpg`}
                            alt="Redbull Marketing" />
                    case 'coke-1':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-3.jpg`}
                            alt="Coke Marketing" />
                    case 'coke-2':
                        return <img className="border-b border-gray-200" src={`${process.env.PUBLIC_URL}/img/coke-marketing-3.jpg`}
                            alt="Coke Marketing" />
                    default: return
                }
            }
        }
    }

    const getMarketingTagline = (collection) => {
        let campaign = collection.campaign ? collection.campaign : 'none'
        if (type == 'restaurant') {
            switch (campaign) {
                case 'redbull':
                    return <p className="text-white text-center font-bold text-lg">Redbull Wiiings for Every Taste</p>
                case 'coke-1':
                    return <p className="text-white text-center font-bold text-lg">Real Magic</p>
                case 'coke-2':
                    return <p className="text-white text-center font-bold text-lg">Real Magic</p>
                default: return
            }
        }
    }

    return (
        <>
            {display === 'sidebar-vertical' && (
                <>
                    <div></div>
                    <div className="p-1 border border-gray-100">
                        {getMarketingImage(display, type, collection)}
                        {getMarketingTagline(type, collection)}
                        <h4 className="font-bold mt-3 text-xs text-right">Paid Advertisement</h4>
                    </div>
                </>
            )}
            {display === 'main-horizontal' && (
                <div className="marketing-banner-main mt-8">
                    {getMarketingImage(display, type, collection)}
                    {getMarketingTagline(type, collection)}
                    <h4 className="font-bold mt-3 text-xs text-right">Paid Advertisement</h4>
                </div>
            )}
        </>
    )
}
