import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useCollection } from '../hooks/useCollection'
import { useDocument } from '../hooks/useDocument'
import Asia from '../templates/Asia'
import Campaign from '../components/Campaign'

// styles
import './Menu.css'
import MenuGroup from '../components/MenuGroup'

function buildCSS(document, key, defaultVal) {
    var css = ''
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        css += ' ' + document.styles[key]
    } else if (defaultVal) {
        css += ' ' + defaultVal
    }

    return css
}

export default function Menu() {
    const { name, id } = useParams()
    const { documents: restaurants, error: restaurantError } = useCollection('restaurants', ['path', '==', name], null, 1)
    const { document: menu, error: menuError } = useDocument('menus', id)
    const { documents: menuGroups, error: menuGroupError } = useCollection('menuGroups', ['parentRef', '==', id])

    if (restaurantError) {
        return <div className='error'>{restaurantError}</div>
    }

    if (menuError) {
        return <div className='error'>{menuError}</div>
    }

    if (menuGroupError) {
        return <div className='error'>{menuGroupError}</div>
    }

    if (!restaurants || !menu || !menuGroups) {
        return <div className='loading'>Die Seite wird geladen...</div>
    }

    const restaurant = restaurants[0]

    // Title
    var titleCSS = buildCSS(menu, 'titleFontFamily')
    titleCSS += buildCSS(menu, 'titleFontSize', 'text-2xl')
    titleCSS += buildCSS(menu, 'titleFontStyle')

    // Description 
    var descriptionCSS = buildCSS(menu, 'descriptionFontFamily')
    descriptionCSS += buildCSS(menu, 'descriptionFontSize', 'text-base')
    descriptionCSS += buildCSS(menu, 'descriptionFontStyle')

    return (
        <Asia restaurant={restaurant}>
            <header>
                <h2 className={'text-gray-600 text-center mt-2 font-semibold ' + titleCSS}>
                    <Link className='' to={`/${restaurant.path}`}>
                        <span className='return-menu-main font-extralight'></span>{menu.title}
                    </Link>
                </h2>
                <h3 className={'text-center font-semibold mt-2 pb-3' + descriptionCSS}>{menu.description}</h3>
            </header>
            <MenuGroup menu={menu} groups={menuGroups} />
            {(restaurant.campaign !== '' && restaurant.campaign !== 'none') && <Campaign display='main-horizontal' type='restaurant' collection={restaurant} />}
        </Asia>
    )
}
