import firebase from 'firebase/app'
import 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCptxpy7KQDpu1mVHShj9wKHso1IZyYFlY",
    authDomain: "mamenu-v2.firebaseapp.com",
    projectId: "mamenu-v2",
    storageBucket: "mamenu-v2.appspot.com",
    messagingSenderId: "710028367987",
    appId: "1:710028367987:web:07742ce3f2d230f81bf206",
    measurementId: "G-QF5HDEJS73"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, timestamp }