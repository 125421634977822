// styles
import './CakeAndBakery.css'

import React from 'react'

export default function CakeAndBakery() {
    return (
        <div>CakeAndBakery</div>
    )
}
