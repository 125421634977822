import { BrowserRouter, Route, Switch } from 'react-router-dom';

// styles
import './App.css'
import LandingPage from './pages/LandingPage';

// pages and components
import Mamenu from './pages/Mamenu.js'
import Menu from './pages/Menu.js'

function App() {
  return (
    <div className="App h-full">
      <BrowserRouter>
        {/* <div className="container sm:max-w-full md:px-1"> */}
        <div className="container mx-auto ">
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/:name">
              <Mamenu />
            </Route>
            <Route path="/:name/menu/:id">
              <Menu />
            </Route>
            <Route path="*">
              {/* <Redirect to="/" />} */}
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App
