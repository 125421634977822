import React, { useEffect } from 'react'
import { useCollection } from '../hooks/useCollection'

export default function MenuItemList({ showDetails, group }) {
    const { documents: menuItems, error } = useCollection('menuItems', ['parentRef', '==', group.id])

    useEffect(() => {
        const detailsMoreInfo = document.getElementsByClassName('details-more-info')
        Array.from(detailsMoreInfo).forEach((el) => {
            if (el.getElementsByClassName('moreInfoNotEmpty').length == 0) {
                el.remove()
                return false
            }
        })
    })

    if (error) {
        return <div className='error'>{error}</div>
    }

    if (!menuItems) {
        return <div className='loading'>Die Seite wird geladen...</div>
    }

    // Sort menu items
    const sortedMenuItems = group.itemOrder.map(id => {
        return menuItems.find(element => element.id === id)
    })

    // Display the title and description and call displayMoreInfo to
    const displayInfo = (item) => {
        return item.price.map(p => {
            if (p.description !== '') {
                return <span className="block text-gray-500 text-sm">{p.description} - {p.price}</span>
            } else {
                return (p.description === '') && <span className="block text-gray-500 text-sm">{p.price}</span>
            }
        })
    }

    // return the allergen and ingredients per item
    // item.price structure is [{string:price, string:description, array:alleregen, array:ingredients}]
    const moreInfo = (item) => {
        return item.price.map(displayMoreInfo)
    }

    const displayMoreInfo = (price) => {
        var moreInfoIngredients = []
        var moreInfoAllergens = []
        var moreInfo = []
        var ingredientsWrapper = []
        var allergensWrapper = []

        // Handle ingredients
        if (price.ingredients && price.ingredients.length > 0) {
            moreInfoIngredients = price.ingredients.map((i, idx) => {
                return (price.ingredients.length == idx + 1) ? <span>{i.value}</span> : <span>{i.value} | </span>
            })
        }
        if (moreInfoIngredients.length > 0) {
            const title = <div>Inhaltsstoffe</div>
            moreInfoIngredients.unshift(title)
            ingredientsWrapper = <div className="pb-5">{[...moreInfoIngredients]}</div>
        }

        // Handle allergens 
        if (price.allergens && price.allergens.length > 0) {
            moreInfoAllergens = price.allergens.map((i, idx) => {
                return (price.allergens.length == idx + 1) ? <span>{i.value}</span> : <span>{i.value} | </span>
            })
        }
        if (moreInfoAllergens.length > 0) {
            const title = <div>Allergene</div>
            moreInfoAllergens.unshift(title)
            allergensWrapper = <div className="pb-5">{[...moreInfoAllergens]}</div>
        }

        if (moreInfoAllergens.length || moreInfoIngredients.length) {
            const item = <div className="font-bold">{price.description}</div>
            moreInfo.push(item, ingredientsWrapper, allergensWrapper)
            return <div className="moreInfoNotEmpty">{[...moreInfo]}</div>
        } else {
            return <div className="moreInfoEmpty"></div>
        }

    }

    return (
        <div className='mx-4 mt-8' style={{ display: showDetails ? ' block' : ' none' }}>
            {(sortedMenuItems.length === 0) && <p>You currently have no items</p>}
            <ul>
                {sortedMenuItems.map((item, idx) => (
                    !item.isHidden && (<li className='mb-2' key={item.id}>
                        <div className="text-center">
                            <span className="text-lg font-bold">{item.title}</span>
                            <span className="block text-gray-500 text-sm">{item.description}</span>
                            {displayInfo(item)}
                            <details className={"details-more-info details" + idx + " my-4 text-center"}>
                                <summary className="details-no-image font-medium text-gray-400 block">
                                    Inhaltsstoffe & Allergene
                                </summary>
                                <div className="bg-gray-100 pt-4 px-4">{moreInfo(item)}</div>
                            </details>
                        </div>
                    </li>)
                ))}
            </ul>
        </div>
    )
}
