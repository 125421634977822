import React from 'react'
import MenuItemList from '../../MenuItemList'

export default function Normal({ group, titleCSS, descriptionCSS }) {
    return (
        <div className="card">
            <div className="m-4 text-center">
                <span className={"font-bold " + titleCSS}>{group.title}</span>
                <span className={"block text-gray-500 " + descriptionCSS}>{group.description}</span>
                <MenuItemList showDetails={true} group={group} />
            </div>
        </div>
    )
}
