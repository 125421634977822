import React from 'react'
import { SocialIcon } from 'react-social-icons'

export default function SocialButtons({ restaurant }) {
    let links = restaurant.socialMediaLinks ? restaurant.socialMediaLinks : null
    return (
        <>
            {
                (links !== null && links.length > 0) && (
                    <>
                        <h4 className="font-bold mt-3 mb-3 text-xs text-center">Folge uns online</h4>
                        <div className="flex justify-center">
                            {links.map((link) => {
                                return <div className="px-2 text-center">
                                    <div className="flex-none w-14"></div>
                                    <div className="grow">
                                        <SocialIcon style={{ height: 40, width: 40 }} url={link} bgColor={"#4B5563"} />
                                    </div>
                                    <div className="flex-none w-14"></div>
                                </div>
                            })}
                        </div>
                    </>
                )
            }
        </>
    )
}
