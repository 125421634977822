import React from 'react'
import Navbar from '../components/Navbar'

// styles
import './SushiBar.css'

export default function SushiBar({ restaurant, children }) {
    return (
        <div className="grid md:grid-cols-3">
            <Navbar restaurant={restaurant} />
            <main className="px-4 md:px-16 py-6 bg-gray-100 md:col-span-2">
                <div className="flex items-center justify-center">
                    <img src={`${process.env.PUBLIC_URL}/img/logo-steirer-400x120.png`} alt="" />
                </div>
                <header>
                    <h2 className="text-gray-600 text-2xl text-center mt-2 font-semibold">{restaurant.name}</h2>
                    <h3 className="text-center font-semibold mt-2">{restaurant.details}</h3>
                </header>

                <div>
                    {children}
                </div>
            </main>
        </div>
    )
}
