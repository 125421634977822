// Vertical centering of absolute positioned text
// https://www.smashingmagazine.com/2013/08/absolute-horizontal-vertical-centering-css/

import React from 'react'
import { Link } from 'react-router-dom'
import { useCollection } from '../hooks/useCollection'
import './MenuList.css'
import Campaign from '../components/Campaign.js'

function buildCSS(document, key, defaultVal) {
    var css = ''
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        css += ' ' + document.styles[key]
    } else if (defaultVal) {
        css += ' ' + defaultVal
    }

    return css
}

function buildImg(document, key, defaultVal) {
    var src = null
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        src = document.styles[key]
    } else if (defaultVal) {
        src = defaultVal
    }

    if (src) {
        // return <img src={src} className="w-full h-32 sm:h-48 object-cover" />
        return <img src={src} className="transparent-img" />
    } else {
        return null
    }
}

function buildBgURL(document, key, defaultVal) {
    // return "url(/img/hochreiter/speisekarte.jpeg)"
    var url = null
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        url = document.styles[key]
    } else if (defaultVal) {
        url = defaultVal
    }

    if (url) {
        return "url(" + url + ")"
    } else {
        return null
    }
}

export default function MenuList({ restaurant }) {
    const { documents: menus, error } = useCollection('menus', ['parentRef', '==', restaurant.id])

    if (error) {
        return <div className='error'>{error}</div>
    }

    if (!menus) {
        return <div className='loading'>Die Seite wird geladen...</div>
    }

    // Title
    var titleCSS = buildCSS(restaurant, 'menuListTitleFontFamily')
    titleCSS += buildCSS(restaurant, 'menuListTitleFontSize', 'text-lg')
    titleCSS += buildCSS(restaurant, 'menuListTitleFontStyle')

    // Description 
    var descriptionCSS = buildCSS(restaurant, 'menuListDescriptionFontFamily')
    descriptionCSS += buildCSS(restaurant, 'menuListDescriptionFontSize', 'text-sm')
    descriptionCSS += buildCSS(restaurant, 'menuListDescriptionFontStyle')

    // Sort menus
    const sortedMenus = restaurant.menuOrder.map(id => {
        return menus.find(element => element.id === id)
    })

    return (
        <div className='mt-8'>
            {(sortedMenus.length === 0) && <p>You currently have no menus</p>}
            <ul>
                {sortedMenus.map(menu => menu && (
                    !menu.isHidden && (
                        <li className='mb-8' key={menu.id}>
                            <Link className='' to={`/${restaurant.path}/menu/${menu.id}`} key={menu.id}>
                                <div className="rounded absolute-center relative parent" style={{ backgroundImage: buildBgURL(menu, 'bgImg') }}>
                                    <div className='rounded listed-menu-wrapper'></div>
                                    {buildImg(menu, 'bgImg')}
                                    <div className={"absolute menu-title text-white text-3xl font-black" + titleCSS}>{menu.title}</div>
                                    <div className={"absolute menu-description text-white text-xs font-black " + descriptionCSS}>{menu.description}</div>
                                </div>
                            </Link>
                            {(menu.campaign !== undefined && menu.campaign !== '' && menu.campaign !== 'none') && <Campaign display='main-horizontal' type='menu' collection={menu} />}
                        </li>
                    )
                ))}
            </ul>
        </div>
    )
}
