import { useEffect, useRef, useState } from "react"
import { projectFirestore } from "../firebase/config"

export const use2Collection = (collection1, collection2, _query, _orderBy, limit) => {
    const [documents, setDocuments] = useState(null)
    const [results, setResults] = useState(null)
    const [error, setError] = useState(null)

    const query = useRef(_query).current
    const orderBy = useRef(_orderBy).current

    useEffect(() => {
        let ref = projectFirestore.collection(collection1)

        if (query) {
            // https://firebase.google.com/docs/firestore/query-data/queries
            // Example: const q = query(citiesRef, where("state", "==", "CA"));
            // Thus we spread the query with the ...query spread operator.
            ref = ref.where(...query)
        }

        if (orderBy) {
            ref = ref.orderBy(...orderBy)
        }

        if (limit) {
            ref = ref.limit(limit)
        }

        ref.get()
            .then((querySnapshot) => {
                let docs = []
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                const ids = docs.map(d => {
                    return d.id
                })

                let r = []
                let ref2 = projectFirestore.collection(collection2)
                ref2.where('parentRef', 'in', ids).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            r.push({ ...doc.data(), id: doc.id })
                        })
                        setResults(r)
                    })
                setDocuments(docs)

            })
            .catch((err) => {
                setError(err)
            })
    }, [collection1, collection2, query, orderBy])

    return { documents, results, error }
}