import React, { useState } from 'react'
import MenuItemList from '../../MenuItemList'
import './Details.css'

function buildImg(document, key, defaultVal) {
    // return <img src="/img/hochreiter/speisekarte.jpeg" className="w-full object-cover" />
    var src = null
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        src = document.styles[key]
    } else if (defaultVal) {
        src = defaultVal
    }

    if (src) {
        // return <img src={src} className="w-full h-32 sm:h-48 object-cover" />
        // return <img src={src} className="w-full object-cover" />
        return <img src={src} className="transparent-img" />
    } else {
        return null
    }
}

function buildBgURL(document, key, defaultVal) {
    var url = null
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        url = document.styles[key]
    } else if (defaultVal) {
        url = defaultVal
    }

    if (url) {
        return "url(" + url + ")"
    } else {
        return null
    }
}

export default function Details({ document, titleCSS, descriptionCSS }) {
    const [showDetails, setShowDetails] = useState(false)

    function toggleDetails() {
        setShowDetails(prevState => !prevState)
    }

    return (
        <>
            {(document.styles && document.styles['bgImg'] && document.styles['bgImg'] !== '') && (
                <>
                    <details onClick={toggleDetails} className="rounded absolute-center relative parent" style={{ backgroundImage: buildBgURL(document, 'bgImg') }}>
                        <summary className={"has-image flex justify-center font-bold " + titleCSS}>
                            <div className='rounded listed-menu-wrapper'></div>
                            <div className={"absolute menu-details-title text-white text-3xl font-black" + titleCSS}>{document.title}</div>
                            <div className={"absolute menu-description text-white text-xs font-black " + descriptionCSS}>{document.description}</div>
                            {/* {buildImg(document, 'bgImg')} */}
                        </summary>
                        <span className={"block text-gray-500 " + descriptionCSS}>{document.description}</span>
                    </details>
                    <div className="card mt-2" style={{ display: showDetails ? ' block' : ' none' }}>
                        <MenuItemList showDetails={showDetails} group={document} />
                    </div>
                </>
            )}

            {(!document.styles || document.styles && (!document.styles['bgImg'] || document.styles['bgImg'] === '')) && (
                <div className="card">
                    <details className="m-4 text-center">
                        <summary className={"font-bold details-no-image" + titleCSS}>{document.title}</summary>
                        <span className={"block text-gray-500 " + descriptionCSS}>{document.description}</span>
                        <MenuItemList showDetails={true} group={document} />
                    </details>
                </div>
            )}

        </>
    )
}
