import React from 'react'
import Navbar from '../components/Navbar'

export default function Asia({ restaurant, children }) {
    var css = ''
    if (restaurant.styles && restaurant.styles.bgImg) {
        css = restaurant.styles.bgImg + ' bg-no-repeat bg-cover'
    }

    return (
        <div className="grid md:grid-cols-3">
            <Navbar restaurant={restaurant} />
            <main className={'px-4 md:px-16 py-6 bg-gray-100 md:col-span-2 ' + css}>
                <div className="flex items-center justify-center">
                    <img src={`${process.env.PUBLIC_URL}/img/logo-steirer-400x120.png`} alt="" />
                </div>
                <div>
                    {children}
                </div>
            </main>
        </div>
    )
}
