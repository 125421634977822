import React from 'react'
import MenuItemList from '../components/MenuItemList'
import Details from './layouts/menuGroup/Details'
import Normal from './layouts/menuGroup/Normal'

function buildCSS(document, key, defaultVal) {
    var css = ''
    if (document.styles && document.styles[key] && document.styles[key] !== '') {
        css += ' ' + document.styles[key]
    } else if (defaultVal) {
        css += ' ' + defaultVal
    }

    return css
}

export default function MenuGroup({ menu, groups }) {
    // Title
    var titleCSS = buildCSS(menu, 'menuGroupTitleFontFamily')
    titleCSS += buildCSS(menu, 'menuGroupTitleFontSize', 'text-lg')
    titleCSS += buildCSS(menu, 'menuGroupTitleFontStyle')

    // Description 
    var descriptionCSS = buildCSS(menu, 'menuGroupDescriptionFontFamily')
    descriptionCSS += buildCSS(menu, 'menuGroupDescriptionFontSize', 'text-small')
    descriptionCSS += buildCSS(menu, 'menuGroupDescriptionFontStyle')

    // Sort menus
    const sortedGroups = menu.groupOrder.map(id => {
        return groups.find(element => element.id === id)
    })

    return (
        <>
            {(sortedGroups.length === 0) && <p>You currently have no menu items</p>}
            <ul>
                {sortedGroups.map(group => group && (
                    !group.isHidden && (<li className='mb-8' key={group.id}>
                        {group.layout === 'normal' && <Normal group={group} titleCSS={titleCSS} descriptionCSS={descriptionCSS} />}
                        {group.layout === 'details' && <Details document={group} titleCSS={titleCSS} descriptionCSS={descriptionCSS} />}
                    </li>)
                ))}
            </ul>
        </>
    )
}
