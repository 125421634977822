import React from 'react'

export default function LandingPage() {
    return (
        <div className="bg-gradient-to-b from-cyan-500 to-blue-500 flex h-screen justify-center items-center">
            <div className="text-center">
                <h1 className="mb-2 font-sans text-6xl font-medium tracking-wider text-white">mamenu</h1>
                <p className="text-slate-200 ">Unser Pilotprojekt - Check it out here</p>
                <a className="text-slate-200" href="https://mamenu.de/hochreiter">https://mamenu.de/hochreiter</a>
            </div>
        </div>
    )
}
