// styles
import './Tastyc.css'

import React from 'react'

export default function Tastyc() {
    return (
        <div>Tastyc</div>
    )
}
